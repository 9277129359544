.ticket-ui {
  font-family: Arial, sans-serif;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-left: 1px solid #ccc;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #f9f9f9;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ticket-ui header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ticket-ui h1 {
  font-size: 24px;
  margin: 0;
}
.ticket-details p {
  margin: 0;
}
.comment-section input[type='text'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.close-ticket,
.add-comment {
  width: 200px;
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 200px;
}
.close-ticket:hover,
.add-comment:hover {
  background-color: gray;
}
.add-comment {
  background-color: lightcoral;
  max-width: 200px;
}
.my-textarea {
  width: 100%;
  height: 60px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}
.date-input {
  border: 1px solid #e0e3e7;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 0.875rem;
  color: #333;
  height: 55px;
}
.date-input:hover {
  border-color: black;
}
