.signature-framework {
  font-size: 16px;
  background: linear-gradient(to right, rgb(0, 42, 255) 0%, #00a6ff 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: moveBlurredCircle 5s ease-in-out infinite;
  display: inline-block;
}

@keyframes moveBlurredCircle {
  0% {
    background-position: -100%;
    transform: scale(1);
  }

  50% {
    background-position: 100%;
    transform: scale(1.03);
  }

  100% {
    background-position: -100%;
    transform: scale(1);
  }
}
.main-tab-layout {
  position: fixed;
  left: 0;
  width: 250px;
  height: 100%;
  overflow-y: auto;
  padding-top: 130px;
  padding-left: 10px;
}
.stepperImage {
  width: 80%;
  margin-left: 10px;
}
