/* tooltip.css */

.hover-box {
  position: absolute;
  display: block;
  background-color: rgba(229, 245, 253, 1);
  border: 1px solid #ccc;
  padding: 15px;

  width: 200px;
  height: 131px;
  border-radius: 15px;
}

img:hover + .hover-box + {
  display: block;
}

.arrow-down {
  position: absolute;
  width: 0;
  height: 0;
  top: 129px;
  left: 40%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #d6d7dc;
}

.arrow-down-below {
  position: absolute;
  width: 0;
  height: 0;
  top: 129px;
  left: 40%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #d6d7dc;
}

.arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  top: 55px;
  /* Adjust as needed */
  left: 198px;
  /* Adjust as needed */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #d6d7dc;
}
