.App {
  text-align: center;
}
#setRootBg {
  background-color: rgb(229, 245, 253);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-Icon {
  background-color: black;
  border: 1px solid rgb(202, 194, 194);
  padding: 1.2rem;
}
.tab-render {
  background-color: white;
  border-radius: 20px;
  width: 100%;
}
