.modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.apology-modal {
  width: 561px;
  height: 165px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
}

.modal-text {
  font-size: 18px;
  color: black;
}
