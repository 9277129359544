.zeroProgress {
  color: #000;
  background-color: #fff;
  border: 2px solid hsl(0, 0%, 100%) !important;
  border-radius: 6px;
}

.veryLowProgress {
  background-color: #c4e6f1;
  color: black;
  border: 2px solid hsl(0, 0%, 100%) !important;
  border-radius: 6px;
}
.lowProgress {
  background-color: hsl(197, 75%, 70%);
  color: black;
  border: 2px solid hsl(0, 0%, 100%) !important;
  border-radius: 6px;
}

.highProgress {
  background-color: hsl(202, 82%, 53%);
  color: white;
  border: 2px solid hsl(0, 0%, 100%) !important;
  border-radius: 6px;
}
.veryHighProgress {
  background-color: hwb(202 3% 21%);
  color: white;
  border: 2px solid hsl(0, 0%, 100%) !important;
  border-radius: 6px;
}

.sessionClass {
  background-color: hwb(39 34% 3%);
  color: #4e4d4d;
  border: 2px solid hsl(0, 0%, 100%) !important;
  border-radius: 6px;
}

.react-calendar {
  max-width: 500px;
  margin: 0 auto;
  border: 0px;
}

.react-calendar__navigation__prev2-button[disabled] {
  color: #000;
  background-color: transparent !important;
  border: 0;
  font-weight: 600;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: auto;
}

.react-calendar__navigation {
  margin-bottom: 0;
}

.react-calendar__navigation__label {
  flex-grow: 0 !important;
}

.react-calendar__navigation__arrow .react-calendar__navigation__prev-button {
  margin-left: auto !important;
}

.simpleTiles {
  border: 2px solid hsl(0, 0%, 100%) !important;
  border-radius: 6px;
}
