.ticket-overview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.ticket-alert {
  background-color: #e74c3c;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ticket-count {
  font-size: 24px;
  font-weight: bold;
}

.congratulations {
  background-color: #2ecc71;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  width: 100vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.message {
  margin-bottom: 10px;
  font-size: 24px;
}

.celebration {
  font-size: 50px;
}
