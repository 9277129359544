/* General Ticket Card Styles */
.ticket-card {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 120px;
  margin: 20px;
  background-color: #f3f0f0;
  border-radius: 10px;
  overflow: hidden;
}

/* Circular Cutouts */
.ticket-card::before,
.ticket-card::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: 1px solid #ddd;
}

.ticket-card::before {
  left: -10px;
  clip-path: inset(0 0 0 10px);
}

.ticket-card::after {
  right: -10px;
  clip-path: inset(0 10px 0 0);
}

/* Ticket Card Body */
.ticket-card__body {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Image inside the Ticket Card */
.ticket-card__image img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

/* Divider between Image and Details */
.ticket-card__divider {
  width: 1px;
  height: 70px;
  border-left: 2px dashed #8f8e8e;
  margin: 0 10px;
}

/* Details Section of the Ticket Card */
.ticket-card__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.ticket-card__details h3 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: bold;
}

.ticket-card__details p {
  margin: 3px 0;
  font-size: 0.7rem;
  color: #666;
  line-height: 1.2;
  word-wrap: break-word;
}

/* Price and Link Section */
.ticket-card__price-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-card__price {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

.ticket-card__link {
  font-size: 0.8rem;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.ticket-card__link:hover {
  text-decoration: underline;
}

/* Detailed Ticket Card (in Modal) */
.detail-ticket-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 320px;
  margin: 20px;
  background-color: #a9daea;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Cutouts for Detailed Ticket Card */
.detail-ticket-card::before,
.detail-ticket-card::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 20px;
  background-color: #fff;
  border-radius: 70%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.detail-ticket-card::before {
  top: -10px;
  border-bottom: none;
}

.detail-ticket-card::after {
  bottom: -10px;
  border-top: none;
}

.detail-ticket-card__body {
  padding: 1rem;
}

/* Ellipsis for multi-line truncation */
.ellipsis-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 3em;
}

/* Media Queries for responsiveness */

/* For screens between 600px and 750px */
@media (min-width: 600px) and (max-width: 750px) {
  .ticket-card {
    width: 330px;
    margin: 15px auto;
  }

  .ticket-card__image img {
    width: 65px;
    height: 65px;
  }

  .ticket-card__divider {
    height: 65px;
  }

  .ticket-card__details h3 {
    font-size: 0.85rem;
  }

  .ticket-card__details p {
    font-size: 0.65rem;
  }

  .ticket-card__price {
    font-size: 0.85rem;
  }

  .ticket-card__link {
    font-size: 0.75rem;
  }
}

/* For screens 1024px and greater */
@media (min-width: 1024px) {
  .ticket-card {
    margin: 20px 10px;
    width: 350px;
  }

  .ticket-card__image img {
    width: 70px;
    height: 70px;
  }

  .ticket-card__divider {
    height: 70px;
  }

  .ticket-card__details h3,
  .ticket-card__details p {
    font-size: 0.9rem;
  }

  .ticket-card__price {
    font-size: 0.9rem;
  }

  .ticket-card__link {
    font-size: 0.8rem;
  }

  /* Adjust Grid or Flex container for proper alignment */
  .coupons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
