.helpBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: linear-gradient(90deg, #1976d2 0%, #005db9 100%);
  color: white;
  border-radius: 6px;
  margin-right: 16px;
  padding: 14px 30px 14px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.01em;
}

.choicePopOver {
  position: absolute;
  top: 3.75rem;
  left: -120px;
  color: #000000;
  height: 450px;
  width: 400px;
  background-color: #ffffff; /* White background */
  border: 0.5px solid #000000; /* Black border */
  border-radius: 30px;
  cursor: auto;
}

.choicePopOver::before {
  content: '';
  position: absolute;
  top: -10px; /* Position the arrow above the square */
  left: 11rem;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000000; /* Black arrow */
}

.closeIcon {
  position: absolute;
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
}

.optionHeader {
  display: flex;
  align-items: center;
  padding: 19px 30px;
  border-bottom: 0.5px solid #000000;
  font-size: 20px;
  font-weight: 600;
  line-height: 29.05px;
  letter-spacing: 0.01em;
}

.successContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  gap: 16px;
  height: 28rem;
}

.checkmark {
  font-size: 3rem;
  color: #169e16;
}

.successText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.mainContainer {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
}

.backBtn {
  cursor: pointer;
}

.headingText {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-align: center;
}

.informativeText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.0075em;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
}

.optionCta {
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #000000;
  cursor: pointer;
}

.ctaStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textArea {
  max-height: 88px;
  height: 100%;
  max-width: 100%;
  padding: 12px;
}

.submitCtaContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.submitCTA {
  width: fit-content;
  padding: 15px 30px 15px 30px;
  border-radius: 6px;
  background: #1976d2;
  border: none;
  color: #ffffff;
  cursor: pointer;
}
