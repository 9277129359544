.coupon-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 40px;
  max-width: 500px;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.coupon-card-image img {
  width: 300px;
  height: auto;
  margin-bottom: 20px;
  margin: auto;
}

.coupon-card h1 {
  font-size: 1.6em;
  margin-bottom: 10px;
}

.coupon-card p {
  font-size: 1em;
  margin-bottom: 10px;
  line-height: 0.9;
  color: #666;
}

.coupon-card-button {
  background-color: #ffaa00;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: #000000;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
}

.coupon-card-button:hover {
  background-color: #ffaa00;
}
