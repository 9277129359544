.rectangle-container {
  width: 1000px;
  /* width: 1200px; */
  /* height: 400px; */
  height: 320px;
  position: relative;
  overflow: hidden;
  /* margin-top: -10px; */
  margin: 0 auto 30px auto;
}

.circle-container div {
  width: 200px;
  height: 200px;
  background-color: rgb(25, 96, 245);
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 127%;
  /* top: 137%; */
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease;
}

.circle-container-locked div {
  width: 200px;
  height: 200px;
  background-color: rgb(155, 159, 168);
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 127%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease;
  pointer-events: none;
}

.arrows {
  position: absolute;
  top: 85%;
  left: 45%;
  transform: translateY(-50%);
  display: flex;
}

.arrow {
  font-size: 35px;
  cursor: pointer;
}

.left {
  margin-right: 10px;
}

.right {
  margin-left: 10px;
}

.circle-container div:nth-child(1) {
  transform: translate(-50%, -50%) rotate(90deg) translate(400px) rotate(-90deg);
}

.circle-container div:nth-child(2) {
  transform: translate(-50%, -50%) rotate(126deg) translate(400px)
    rotate(-126deg);
}

.circle-container div:nth-child(3) {
  transform: translate(-50%, -50%) rotate(162deg) translate(400px)
    rotate(-162deg);
}

.circle-container div:nth-child(4) {
  transform: translate(-50%, -50%) rotate(198deg) translate(400px)
    rotate(-198deg);
}

.circle-container div:nth-child(5) {
  transform: translate(-50%, -50%) rotate(234deg) translate(400px)
    rotate(-234deg);
}

.circle-container div:nth-child(6) {
  transform: translate(-50%, -50%) rotate(270deg) translate(400px)
    rotate(-270deg);
}

.circle-container div:nth-child(7) {
  transform: translate(-50%, -50%) rotate(306deg) translate(400px)
    rotate(-306deg);
}

.circle-container div:nth-child(8) {
  transform: translate(-50%, -50%) rotate(342deg) translate(400px)
    rotate(-342deg);
}

.circle-container div:nth-child(9) {
  transform: translate(-50%, -50%) rotate(18deg) translate(400px) rotate(-18deg);
}

.circle-container div:nth-child(10) {
  transform: translate(-50%, -50%) rotate(54deg) translate(400px) rotate(-54deg);
}
