.triangle1 {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle2 {
  position: absolute;
  width: 85px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 35px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle3 {
  position: absolute;
  width: 129px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 70px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle4 {
  position: absolute;
  width: 173px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 105px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle5 {
  position: absolute;
  width: 217px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 140px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle6 {
  position: absolute;
  width: 261px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 175px;
  animation: placementLevelColor 1s linear;
  cursor: pointer;
}

.triangle7 {
  position: absolute;
  width: 306px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 210px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle8 {
  position: absolute;
  width: 351px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 245px;
  animation: placementLevelColor 1s linear; /* Use placement animation */
  cursor: pointer;
  z-index: 110;
}

.triangle9 {
  position: absolute;
  width: 397px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 280px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle10 {
  position: absolute;
  width: 441px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 315px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle11 {
  position: absolute;
  width: 484px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 350px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle12 {
  position: absolute;
  width: 530px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 385px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle13 {
  position: absolute;
  width: 576px;
  height: 0;
  border-bottom: 30px solid #1976d2;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: 420px;
  animation: levelColor 1s linear;
  cursor: pointer;
}

.triangle1,
.triangle2,
.triangle3,
.triangle4,
.triangle5,
.triangle6,
.triangle7,
.triangle8,
.triangle9,
.triangle10,
.triangle11,
.triangle12,
.triangle13 {
  animation-fill-mode: forwards;
}
@keyframes levelColor {
  0% {
    border-bottom: 30px solid #1976d2;
  }

  25% {
    border-bottom: 30px solid #1976d2;
  }

  50% {
    border-bottom: 30px solid #1976d2;
  }

  100% {
    border-bottom: 30px solid #bebebe;
  }
}

@keyframes placementLevelColor {
  0% {
    border-bottom: 30px solid #1976d2;
    z-index: 110;
  }

  25% {
    border-bottom: 30px solid #1976d2;
  }

  50% {
    border-bottom: 30px solid #1976d2;
    /* z-index: 110; */
  }

  100% {
    border-bottom: 30px solid #fbc704;
  }
}
