.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level-text {
  color: #8c8686;
  font-size: 15px;
  opacity: 0;
  animation: levelTextAnimation 1s linear;
}

.level-divider {
  background-color: #bebebe;
  width: 35%;
}

.drawer-title {
  color: black;
  font-size: 30px;
  padding: 20px;
}

.drawer-divider {
  background-color: black;
  width: 100%;
}

.drawer-stats {
  color: black;
  font-size: 15px;
}

.placement-divider {
  background-color: #bebebe;
  width: 95%;
  z-index: 0;
}

.status-text {
  background-color: rgba(251, 199, 4, 0.5);
  border-radius: 100px;
  width: fit-content;
  padding: 7px 20px;
  color: black;
  font-size: 15px;
}

.attemptButton {
  width: 160px;
  height: 44px;
  color: white;
  border-radius: 6px;
  background-color: #1960f5;
}

.attemptButton {
  width: 160px;
  height: 44px;
  color: white;
  border-radius: 6px;
  background-color: #1960f5;
}

.drawer-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.drawer-global-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 20px;
}

.drawer-button-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  gap: 10px;
}

.timer-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  width: 100%;
  text-align: center;
  align-items: center;
  color: #1960f5;
}

.failed-result-text {
  background-color: #fd9f81;
  border-radius: 100px;
  width: fit-content;
  padding: 7px 20px;
  color: black;
  font-size: 15px;
}

.pass-result-text {
  background-color: #97d79d;
  border-radius: 100px;
  width: fit-content;
  padding: 7px 20px;
  color: black;
  font-size: 15px;
}

.timer-text {
  letter-spacing: 2px;
}

.desc-data {
  margin-bottom: 20px;
  width: 100%;
}

.info-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.drawer-stats,
.status-text {
  flex: 0.28;
}

@media screen and (max-width: 1500px) {
  .drawer-stats,
  .status-text {
    flex: 0.4;
  }
}

@media screen and (max-width: 1300px) {
  .drawer-stats,
  .status-text {
    flex: 0.45;
  }
}

@keyframes levelTextAnimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.level-text {
  animation-fill-mode: forwards;
}
