.result-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.pass-level-text-box {
  width: 561px;
  height: 365px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  border-radius: 20px;
}

.fail-level-text-box {
  width: 561px;
  height: 205px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  border-radius: 20px;
}

.gradient-text-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.gradient-text-box {
  background-color: white;
  width: fit-content;
  z-index: 2;
  padding: 0 25px;
}

.gradient-text {
  background-image: linear-gradient(to right, #009af0 0, #fbc704 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  font-family: Inter, sans-serif;
  width: 100%;
  z-index: 3;
}

.gradient-divider-box {
  width: 100%;
  position: absolute;
}

.divider-behind-gradient-text {
  width: 100%;
  background-color: black;
  height: 2px;
}

.gradient-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.modal-text-for-level-pass {
  font-size: 28px;
  color: black;
  font-weight: 600;
}

.modal-text {
  font-size: 18px;
  color: black;
}
