.ticket-detail-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: max-content;
  margin: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.ticket-detail-card__content {
  display: flex;
  width: 100%;
}

.ticket-detail-card__details {
  display: flex;
  flex-direction: column;
  flex: 9;
}

.ticket-detail-card__image {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  object-fit: contain;
}

.ticket-detail-card__info {
  padding: 0 20px;
}

.ticket-detail-card__info h3 {
  margin: 0;
  font-size: 2.4rem;
  font-weight: bold;
}

.ticket-detail-card__info p {
  margin: 5px 0;
  font-size: 0.5rem;
  color: #666;
}

.ticket-detail-card__price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.ticket-detail-card__offer-details {
  margin-top: 10px;
}

.ticket-detail-card__offer-details h4 {
  margin: 10px 0 5px;
  font-size: 0.9rem;
  font-weight: bold;
}

.ticket-detail-card__offer-details ul {
  list-style: disc;
  padding-left: 20px;
  margin: 0;
}

.ticket-detail-card__offer-details p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #666;
}

.ticket-detail-card__button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #1976d2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.ticket-detail-card__button:hover {
  background-color: #0056b3;
}

.ticket-detail-card__divider {
  width: 2px;
  height: auto;
  border-left: 2px dashed #a6a6a6;
  margin: 0 20px;
}

.ticket-detail-card__scratch {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 7;
}

.ticket-detail-card__scratch h4 {
  margin: 0 0 10px;
  font-size: 1.1rem;
  font-weight: bold;
}

.scratch-card {
  width: 300px;
  height: 300px;
  position: relative;
}

.scratch-reveal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  background-color: #ffcc00;
  border-radius: 10px;
}

.ticket-card__scratch a {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
}

.ticket-card__scratch a:hover {
  text-decoration: underline;
}
