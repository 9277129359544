.topics-container {
  display: flex;
  flex-direction: column;
}

.topic-circle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.circles {
  background-color: 'green';
  width: '100px';
  height: '100px';
  border-radius: '50%';
  justify-content: 'center';
}

.description {
  flex: 1;
  font-size: 16px;
}
